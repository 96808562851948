<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Servicios</h3>
                  <div class="nk-block-des text-soft">
                    <p>Se encontraron un total de {{total}} servicios.</p>
                  </div>
                </div><!-- .nk-block-head-content -->
                <div class="nk-block-head-content">
                  <div class="toggle-wrap nk-block-tools-toggle">
                    <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                    <div class="toggle-expand-content" data-content="pageMenu">
                      <ul class="nk-block-tools g-3">
                        <li><router-link to="/sistema/servicios/nuevo" class="btn btn-white btn-outline-dark"><em class="icon ni ni-user-add"></em><span>Nuevo Servicio</span></router-link></li>
                      </ul>
                    </div>
                  </div><!-- .toggle-wrap -->
                </div><!-- .nk-block-head-content -->
              </div><!-- .nk-block-between -->
            </div><!-- .nk-block-head -->
            <TableGrid :rows="rows" :filters="filters" :total="total" @update="update">
              <template #filters>
                <div class="card-tools between-start overflow-auto">
                  <div v-for="filter in filters" class="mr-3" :key="filter.key">
                    <input type="text" class="form-control w-min-100px" :placeholder="filter.label" v-model.lazy="filter.value">
                  </div>
                </div>
              </template>
              <template #header>
                <div class="nk-tb-col"><span class="sub-text">ID</span></div>
                <div class="nk-tb-col"><span class="sub-text">Nombre</span></div>
                <div class="nk-tb-col tb-col-sm"><span class="sub-text">Fecha de Actualización</span></div>
                <div class="nk-tb-col text-right"><span class="sub-text">Acciones</span></div>
              </template>
              <template v-slot:row="{row}">
                <div class="nk-tb-col">
                  <span>{{row.id_servicio}}</span>
                </div>
                <div class="nk-tb-col">
                  <router-link :to="'/sistema/servicios/editar/'+row.id_servicio">
                    <span>{{row.nombre}}</span>
                  </router-link>
                </div>
                <div class="nk-tb-col tb-col-sm">
                  <span>{{$filters.fecha(row.fecha_actualizacion)}}</span>
                </div>
                <div class="nk-tb-col nk-tb-col-tools">
                  <ul class="nk-tb-actions gx-2">
                    <li>
                      <div class="drodown">
                        <a href="#" class="btn btn-sm btn-icon btn-trigger dropdown-toggle" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li><router-link :to="'/sistema/servicios/editar/'+row.id_servicio"><em class="text-primary icon ni ni-edit"></em><span>Editar Servicio</span></router-link></li>
                            <li><a href="#" @click.prevent="remove(row)"><em class="text-danger icon ni ni-file-remove"></em><span>Eliminar Servicio</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </TableGrid>
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import {reactive, ref, toRefs, defineComponent} from "vue";
import AppView from "@/components/sistema/AppView";
import TableGrid from "@/components/TableGrid";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import {useRouter} from "vue-router";
export default defineComponent({
  name: "Servicios",
  components: {TableGrid, AppView},
  setup() {
    const router = useRouter();

    const state = reactive({
      rows: [],
      total: 1
    });
    const filters = ref([
      {
        key: 'nombre',
        label: 'Nombre',
        value: ''
      }
    ]);

    const { loading, make } = useApi();

    async function update(config){
      loading.message = 'Obteniendo información';
      let {data} = await make('servicios/get-all', config);
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          state.rows = data.rows;
          state.total = data.total;
        }
      }
    }

    async function remove(servicio){
      Swal.fire({
        title: 'Eliminar servicio',
        text: '¿Estás seguro que deseas eliminar el servicio ' + servicio.nombre + '?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async res => {
        if(res.isConfirmed){
          loading.message = 'Eliminando servicio';
          let {data} = await make('servicios/delete', {id_servicio: servicio.id_servicio});
          if(data === null){
            Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
          } else {
            if(data.error){
              Swal.fire('Error', data.message, 'error');
            } else {
              router.go();
            }
          }
        }
      });

    }

    return { ...toRefs(state), filters, remove, update }
  }
});
</script>

<style scoped>
</style>